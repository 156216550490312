.img-carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    /* background-color: #f8f9fa;  */
    padding: 20px 0;
  }

  .img-carousel-container h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  
  .img-carousel {
    display: flex;
    animation: scroll-img-carousel 15s linear infinite;
    gap: 20px; /* Space between images */
  }
  
  .img-carousel-item {
    flex-shrink: 0;
    width: 300px; /* Adjust based on desired image size */
    text-align: center;
  }
  
  .img-carousel-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .img-carousel-caption {
    margin-top: 8px;
    font-size: 16px;
    color: #333;
  }
  
  /* Modal container */
.preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Overlay with a soft gradient effect */
  .preview-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgb(255, 255, 255), rgba(0, 0, 0, 0.6));
    z-index: 1;
    backdrop-filter: blur(10px);
  }
  
  /* Preview content box */
  .preview-content {
    position: relative;
    z-index: 2;
    background: #ffffff;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    max-width: 90%;
    max-height: 90%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
  
  /* Image styling */
  .preview-image {
    max-width: 100%;
    max-height: 70vh;
    margin-bottom: 15px;
    border-radius: 12px;
    border: 3px solid #e7ff85;
  }
  
  /* Title styling */
  .preview-title {
    font-size: 1.8rem;
    color: #f5f5f5;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Close button */
  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 10px 15px;
    background: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease;
  }
  
  .close-btn:hover {
    background: #c0392b;
    transform: scale(1.1);
  }
  
  .close-btn:active {
    transform: scale(0.95);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .preview-content {
      padding: 15px;
    }
  
    .preview-title {
      font-size: 1.5rem;
    }
  }
  
  


  @keyframes scroll-img-carousel {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  