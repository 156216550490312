
/* Marquee Section Styles */
.marquee-section {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.gallery-marquee {
  padding: 10px 0;
}

.marquee-item {
  width: 300px;
  height: 200px;
  margin: 0 10px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
}

.marquee-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.marquee-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.marquee-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  display: flex;
  align-items: flex-end;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.marquee-item:hover .marquee-overlay {
  opacity: 1;
}

.marquee-title {
  color: white;
  margin: 0;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

/* Gallery Grid Styles */
.gallery-item {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.1);
}

.gallery-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.gallery-title {
  color: white;
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
}

/* Modal Styles */
.gallery-modal .modal-content {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.modal-image {
  width: 100%;
  height: auto;
  max-height: 85vh;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

.modal-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  padding: 2rem 1.5rem 1.5rem;
  color: white;
  text-align: center;
}

.modal-caption h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.modal-close {
  position: absolute;
  top: -50px;
  right: 0;
  background: transparent;
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;
  z-index: 1060;
  transition: all 0.3s ease;
}

.modal-close:hover {
  transform: scale(1.1) rotate(90deg);
  color: #ff4444;
}

.modal-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 1rem 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1060;
  border-radius: 50px;
}

.modal-nav:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: translateY(-50%) scale(1.1);
}

.modal-prev {
  left: 1rem;
}

.modal-next {
  right: 1rem;
}

/* Pagination Styles */
.pagination {
  margin: 2rem 0;
}

.page-item .page-link {
  color: #333;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.2rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: white;
}

.page-item:not(.active) .page-link:hover {
  background-color: #e9ecef;
  transform: translateY(-2px);
}

/* Animation */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal.show .modal-content {
  animation: modalFadeIn 0.3s ease forwards;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .marquee-item {
    width: 250px;
    height: 180px;
  }
}

@media (max-width: 768px) {
  .marquee-item {
    width: 200px;
    height: 150px;
  }

  .gallery-item {
    height: 200px;
  }

  .modal-nav {
    padding: 0.75rem 0.5rem;
  }

  .modal-caption h3 {
    font-size: 1.2rem;
  }

  .marquee-title {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .marquee-item {
    width: 180px;
    height: 120px;
  }

  .gallery-item {
    height: 180px;
  }

  .gallery-title {
    font-size: 1rem;
  }

  .modal-nav {
    padding: 0.5rem 0.375rem;
  }

  .modal-close {
    top: -40px;
  }

  .marquee-title {
    font-size: 0.8rem;
  }
}