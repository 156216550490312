.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.custom-btn {
  background-color: #fa922a;
  color: #212121;
  font-weight: bold;
  text-align: center;
}

.custom-btn:hover {
  background-color: #e67e22; /* Adjust the hover background color as needed */
  color: #ffffff; /* Adjust the hover text color as needed */
}

.App-link {
  color: #61dafb;
}


.testimonial-card {
  background-color: #f8f9fa;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-heading {
  color: #333;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}



.author-image {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.testimonial-text {
  font-size: 18px;
  margin-bottom: 10px;
}

.testimonial-author {
  font-size: 16px;
  font-style: italic;
}

/* CSS Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.9); /* Optional: You can adjust the scale of the element */
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Apply animation to portfolio items */
.portfolio-item {
  animation: fadeIn 0.5s ease forwards; /* Adjust the animation duration as needed */
}

/* Optional: Delay animation for each portfolio item */
.portfolio-item:nth-child(odd) {
  animation-delay: 0.2s; /* Delay each odd item by 0.2s */
}
.portfolio-item:nth-child(even) {
  animation-delay: 0.4s; /* Delay each even item by 0.4s */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.service-item {
  
  height: 40rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    position: relative;
    display: inline-block;
    width: auto;
    text-align: center;
    color: transparent; /* Hide the original text */
}

.carousel-control-prev-icon::before,
.carousel-control-next-icon::before {
    content: '';
    display: inline-block;
    border: solid white; /* Use white color for the arrows */
    border-width: 0 3px 3px 0; /* Adjust these values to change the arrow size */
    padding: 3px;
}

.carousel-control-prev-icon::before {
    transform: rotate(135deg); /* Rotate for the previous arrow direction */
}

.carousel-control-next-icon::before {
    transform: rotate(-45deg); /* Rotate for the next arrow direction */
}

/* Additional styling for positioning and visibility */
.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: white;
    
    padding: 10px;
    cursor: pointer;
    border: none;
    width: auto;
    margin: 0 15px;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

/* Ensure the arrows are easily visible */
.carousel-control-prev-icon::before,
.carousel-control-next-icon::before {
    color: white;
    text-shadow: 0 0 5px rgba(0,0,0,0.7); /* Optional: adds a shadow for better visibility */
}

.carousel-item img {
  height: 400px; /* Set the height of the images */
  object-fit: cover; /* Ensure the images cover the entire area */
}
.logoTitle {
  font-family: "NeuropolX";
  }
  

@media (max-width: 768px) {
  .carousel-item img {
      height: 300px; /* Reduce the height of the images for smaller screens */
  }
}